import React from 'react';

import './App.css';
import { Routes, Route } from 'react-router-dom';
import Documents from './pages/Documents';
import Home from './pages/Home';

function App() {
  return (
    <Routes>
      <Route path="/" Component={Home} />
      <Route path="/documents" Component={Documents} />
    </Routes>
  );
}

export default App;
