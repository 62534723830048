/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Button, Table } from 'reactstrap';

function Documents() {
  return (
    <Table>
      <thead>
        <tr>
          <th>type</th>
          <th>send date</th>
          <th>signed date</th>
          <th>action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>studentenverklaring</td>
          <td>01/07/2023</td>
          <td>07/07/2023</td>
          <td>
            <Button type="button" id="send-studentenverklaring">
              Send
            </Button>
          </td>
        </tr>
        <tr>
          <td>onthaaldocument</td>
          <td />
          <td />
          <td>
            <Button type="button" id="send-onthaaldocument">
              Send
            </Button>
          </td>
        </tr>
        <tr>
          <td>werkpostfiche</td>
          <td />
          <td />
          <td>
            <Button type="button" id="send-werkpostfiche">
              Send
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default Documents;
